import { DefaultValues, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from '@skywatch/ui'
import { AffiliateEditDetails, AffiliateEditType } from './types/affiliate'

type AffiliateEditFormProps = {
  onSubmitForm: (data: AffiliateEditType) => void
  defaultValues?: DefaultValues<AffiliateEditType>
}

export default ({ onSubmitForm, defaultValues }: AffiliateEditFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AffiliateEditType>({
    resolver: zodResolver(AffiliateEditDetails),
    defaultValues: defaultValues || {},
  })
  return (
    <>
      <form id="affiliate-edit-form" onSubmit={handleSubmit(onSubmitForm)}>
        <Form.Grid>
          <Form.Control className="text-xl font-semibold md:!col-span-6">Edit Agency Details</Form.Control>

          {/* Agency Name */}
          <Form.Control className="md:!col-span-6">
            <Form.Label>Agency Name</Form.Label>
            <Form.Input
              className={`input ${errors.name && 'input-error'}`}
              {...register('name')}
              placeholder="Agency name"
            />
            <Form.Error className={`${!errors.name?.message && 'hidden'}`}>{errors.name?.message}</Form.Error>
          </Form.Control>

          {/* Description */}
          <Form.Control className="md:!col-span-6">
            <Form.Label>Agency Description</Form.Label>
            <Form.Input
              className={`input ${errors.description && 'input-error'}`}
              {...register('description')}
              placeholder="For example: Gallagher Drone Insurance powered by SkyWatch.AI"
            />
            <Form.Error className={`${!errors.description?.message && 'hidden'}`}>
              {errors.description?.message}
            </Form.Error>
          </Form.Control>
        </Form.Grid>
      </form>
    </>
  )
}
