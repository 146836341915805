import * as E from 'effect/Either'
import { pipe } from 'effect/Function'
import { createResource } from '@skywatch/core'
import {
  createAffiliateEndpoint,
  createAffiliateWithParentEndpoint,
  disableAffiliateEnpoint,
  getAffiliateByKeyEndpoint,
  updateAffiliateEndpoint,
} from '~/api/affiliate'
import { Affiliate } from './types/affiliate'
import { AffiliateEditType, AffiliateNewType } from '../forms/types/affiliate'

const initialState: Affiliate = {
  id: '',
  name: '',
  parentsKeys: [],
  disabled: false,
  url: '',
  logoUrl: '',
  description: '',
}

const createAffiliate = async (affiliateDetails: AffiliateNewType) => {
  const apiAffiliate = Affiliate.toPostApi(affiliateDetails)

  try {
    if (apiAffiliate.parentKey) {
      const res = await createAffiliateWithParentEndpoint({
        payload: apiAffiliate,
        urlParams: { affiliateKey: apiAffiliate.parentKey },
      })
      return pipe(
        res,
        E.match({
          onLeft: error => {
            throw error
          },
          onRight: apiResponse => {
            const affiliateKey = apiResponse.data.affiliateKey
            return {
              id: affiliateKey,
              name: apiAffiliate.affiliateName,
              description: apiAffiliate.description,
              logoUrl: apiAffiliate.logoUrl,
              parentsKeys: [apiAffiliate.parentKey],
              url: '',
              disable: false,
            }
          },
        })
      )
    }
    const res = await createAffiliateEndpoint({ payload: apiAffiliate })

    const re = pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          const affiliateKey = apiResponse.data.affiliateKey
          return {
            id: affiliateKey,
            name: apiAffiliate.affiliateName,
            description: apiAffiliate.description,
            logoUrl: apiAffiliate.logoUrl,
            parentsKeys: [apiAffiliate.parentKey],
            url: '',
          }
        },
      })
    )
    return re
  } catch (e) {
    throw e
  }
}

const fetchAffiliateByKey = async (key: string): Promise<Affiliate> => {
  try {
    const res = await getAffiliateByKeyEndpoint({ urlParams: { affiliateKey: key } })

    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          return Affiliate.fromApi(apiResponse.data)
        },
      })
    )
  } catch (e) {
    throw e
  }
}

const updateAffiliate = async (affiliate: Affiliate, affiliateUpdated: AffiliateEditType) => {
  try {
    const res = await updateAffiliateEndpoint({
      urlParams: { affiliateKey: affiliate.id },
      payload: { affiliateName: affiliateUpdated.name, description: affiliateUpdated.description },
    })

    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          return {
            id: affiliate.id,
            name: affiliateUpdated.name,
            description: affiliateUpdated.description,
            parentsKeys: [affiliate.parentsKeys],
            logoUrl: '',
            url: '',
            disabled: false,
          }
        },
      })
    )
  } catch (e) {
    throw e
  }
}

const deactivateAffiliate = async (key: string) => {
  try {
    const res = await disableAffiliateEnpoint({ urlParams: { affiliateKey: key } })

    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: () => {
          return key
        },
      })
    )
  } catch (e) {
    throw e
  }
}

export default createResource({
  name: 'affiliate',
  initialState,
  asyncMethods: { fetchAffiliateByKey, updateAffiliate, deactivateAffiliate, createAffiliate },
})
