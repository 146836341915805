import { z } from 'zod'
import BrokerDetails from './broker'

const AffiliateNewDetails = z.object({
  description: z.string().trim().optional(),
  name: z.string().trim().min(1, { message: 'Required' }),
  network: z.string().optional(),
  admin: BrokerDetails,
})

export type AffiliateNewType = z.infer<typeof AffiliateNewDetails>

const AffiliateEditDetails = z.object({
  description: z.string().optional(),
  name: z.string().min(1, { message: 'Required' }),
})

export type AffiliateEditType = z.infer<typeof AffiliateEditDetails>

export { AffiliateNewDetails, AffiliateEditDetails }
