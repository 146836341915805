import { DefaultValues, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { EmailIcon, Form, UserIcon } from '@skywatch/ui'
import { AffiliateNewDetails, AffiliateNewType } from './types/affiliate'

type AffiliateNewFormProps = {
  onSubmitForm: (data: AffiliateNewType) => void
  defaultValues?: DefaultValues<AffiliateNewType>
  parentName?: string | null
}

export default ({ onSubmitForm, defaultValues, parentName }: AffiliateNewFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AffiliateNewType>({
    resolver: zodResolver(AffiliateNewDetails),
    defaultValues: defaultValues || {},
  })

  return (
    <>
      <form id="affiliate-new-form" onSubmit={handleSubmit(onSubmitForm)}>
        <Form.Grid className="p-6">
          <div className="text-s !col-span-6 font-semibold">Agency details</div>
          {/* Agency Name */}
          <Form.Control className="!col-span-3">
            <Form.Label className="!mt-0">Agency Name</Form.Label>
            <Form.Input
              className={`input ${errors.name && 'input-error'}`}
              {...register('name')}
              placeholder="Agency name"
            />
            <Form.Error className={`${!errors.name?.message && 'hidden'}`}>{errors.name?.message}</Form.Error>
          </Form.Control>

          {/* Network */}
          <Form.Control className="!col-span-3">
            <Form.Label className="!mt-0">Network</Form.Label>
            <Form.Input disabled={true} className="input" placeholder={`${parentName ? parentName : 'None'}`} />
          </Form.Control>

          {/* Description */}
          <Form.Control className="md:!col-span-6">
            <Form.Label className="!mt-0">Description</Form.Label>
            <Form.Input
              className={`input ${errors.description && 'input-error'}`}
              {...register('description')}
              placeholder="For example: Gallagher Drone Insurance powered by SkyWatch.AI"
            />
            <Form.Error className={`${!errors.description?.message && 'hidden'}`}>
              {errors.description?.message}
            </Form.Error>
          </Form.Control>

          <div className="text-s !col-span-6 mt-3 font-semibold">Primary Agent details</div>
          {/* First Name */}
          <Form.Control className="md:!col-span-3">
            <Form.Label className="!mt-0">First name</Form.Label>
            <Form.Input
              icon={
                <UserIcon className={`${errors.admin?.firstName?.message ? 'stroke-error-400' : 'stroke-black'}`} />
              }
              className={`input ${errors.admin?.firstName && 'input-error'}`}
              {...register('admin.firstName')}
              placeholder="First name"
            />
            <Form.Error className={`${!errors.admin?.firstName?.message && 'hidden'}`}>
              {errors.admin?.firstName?.message}
            </Form.Error>
          </Form.Control>
          {/* Last Name */}
          <Form.Control className="md:!col-span-3">
            <Form.Label className="!mt-0">Last name</Form.Label>
            <Form.Input
              icon={<UserIcon className={`${errors.admin?.lastName?.message ? 'stroke-error-400' : 'stroke-black'}`} />}
              className={`input ${errors.admin?.lastName && 'input-error'}`}
              {...register('admin.lastName')}
              placeholder="Last name"
            />
            <Form.Error className={`${!errors.admin?.lastName?.message && 'hidden'}`}>
              {errors.admin?.lastName?.message}
            </Form.Error>
          </Form.Control>

          {/* Email Name */}
          <Form.Control className="md:!col-span-3">
            <Form.Label className="!mt-0">Email</Form.Label>
            <Form.Input
              icon={<EmailIcon className={`${errors.admin?.email?.message ? 'stroke-error-400' : 'stroke-black'}`} />}
              className={`input ${errors.admin?.email && 'input-error'}`}
              {...register('admin.email')}
              placeholder="Email"
            />
            <Form.Error className={`${!errors.admin?.email?.message && 'hidden'}`}>
              {errors.admin?.email?.message}
            </Form.Error>
          </Form.Control>

          {/* phone number  */}
          <Form.Control className="md:!col-span-3">
            <Form.Label className="!mt-0">Phone number</Form.Label>
            <Form.Input
              icon={
                <UserIcon className={`${errors.admin?.phoneNumber?.message ? 'stroke-error-400' : 'stroke-black'}`} />
              }
              className={`input ${errors.admin?.phoneNumber && 'input-error'}`}
              {...register('admin.phoneNumber')}
              placeholder="Phone number"
            />
            <Form.Error className={`${!errors.admin?.phoneNumber?.message && 'hidden'}`}>
              {errors.admin?.phoneNumber?.message}
            </Form.Error>
          </Form.Control>
        </Form.Grid>
      </form>
    </>
  )
}
